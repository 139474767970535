@font-face {
  font-family: 'Lato';
  src: local('Lato'), url("../fonts/Lato/Lato-Regular.woff2") format('woff2');
}

header {
  font-family: Lato;
  /* color: #002b54; */
}

body {
  font-family: Lato;
  /* color: #002b54; */
  font-size: 1.10rem;
}

label {
  text-align: right;
  padding-right: 15px;
}

li {
  list-style-type: none;
}

a {
  text-decoration: none;
}

textarea {
  /* color: #002b54; */
  background-color: #e6e8e6;
  resize: none;
  border: none;
  border-radius: 2px;
  height: 175px;
  width: 200px;
}

input,
select {
  /*input is for text boxes, select is for dropdowns*/
  /* color: #002b54; */
  background-color: #e6e8e6;
  border: none;
  border-radius: 2px;
}

select {
  height: 29.58px;
}

option {
  /* this is for items in dropdown list*/
  /* color: #002b54; */
  background-color: white;
}

span.orb {
  background: #5178D0;
  border-radius: 0.8em;
  -moz-border-radius: 0.8em;
  -webkit-border-radius: 0.8em;
  color: #ffffff;
  display: inline-block;
  font-weight: bold;
  line-height: 1.6em;
  margin-right: 15px;
  text-align: center;
  width: 1.6em;
}

/* .accordion-button { */
/* Used on the report page and billing account page accoridan menus*/
/* color: #002b54; */
/* } */

.floatingLabel {
  text-align: right;
  padding-right: 0%;
  float: right;
  white-space: normal;
}

.orderDetailPageRows>div>label {
  /*styles labels on order detail page*/
  text-align: right;
  padding-right: 0%;
  float: right;
  white-space: normal;
}

.orderDetailPageRows>div>input {
  /*styles input boxes on order detail page*/
  width: 90%;
}

input:focus,
textarea:focus,
select:focus {
  background-color: white;
  border: none;
  outline-color: #eb6600;
}

/* input:read-only, textarea:read-only {
  background: none;
  border: none;
  pointer-events: auto;
} */

.accountPage>select {
  background: white;
  color: black;
  width: 12rem;
  border: 1px solid black;
  border-radius: 4px;
}

input.form-input:read-only,
textarea:read-only {
  background: none;
  border: none;
  pointer-events: auto;
}

input[type=submit] {
  /*this is for buttons*/
  background-color: #eb6600;
  color: #ffffff;
  padding: 8px 12px;
  border-radius: 4px;
  pointer-events: auto;
}

/* Used in billingaccountdetail.js */
.form-label {
  margin-top: 10px;
  margin-bottom: 0px;
}

.form-input {
  width: 275px !important;
}

.adminFormLabel {
  text-align: right;
  padding-right: 0%;
  /* float: right; */
  white-space: normal;
  display: inline;
}

input.adminFormInput:read-only {
  background: none;
  border: none;
  pointer-events: auto;
}

.adminFormInput {
  width: 100% !important;
  background-color: white;
  border: 1px solid black;
  border-radius: 4px;
  height: auto !important;
  padding: 0%;
}

.adminFormInput:focus {
  border: 1px solid black;
  border-radius: 4px;
}

.bootstrapForm {
  display: block;
  height: calc(1.5em + .50rem);
  padding: .375rem .75rem;
  line-height: 1.5;
  background-clip: padding-box;
  background-color: white;
  width: 100% !important;
  border: 1px solid black;
  border-radius: 4px;
}

.spinner-border {
  color: #002b54 !important;
}

.btn-primary.dropdown-toggle {
  /*Used for bootstrap dropdowns on report page*/
  color: black;
  background-color: #e6e8e6 !important;
  border: none !important;
  border-radius: 2px;
  width: 200px;
  height: 29.58px;
  font-size: 1.10rem;
  text-align: left;
  padding: 0px 5px;
}

.btn-primary.dropdown-toggle:focus {
  /*Used for bootstrap dropdowns on report page*/
  box-shadow: 0 0 0 0.15rem #eb6600 !important;
  background-color: white !important;
}

.dropdown-item {
  /*Used for bootstrap dropdowns on report page*/
  /* color: #002b54 !important; */
  padding: 2px;
}

.dropdown-item:active {
  /*Used for bootstrap dropdowns on report page*/
  background-color: transparent;
}

.dropdown-menu li {
  /*Used for bootstrap dropdowns on report page*/
  position: relative;
  cursor: pointer;
}

.dropdown-menu .dropdown-submenu {
  /*Used for bootstrap dropdowns on report page*/
  display: none;
  position: absolute;
  left: 100%;
  top: -7px;
}

.dropdown-menu .dropdown-submenu-left {
  /*Used for bootstrap dropdowns on report page*/
  right: 100%;
  left: auto;
}

.dropdown-menu>li:hover>.dropdown-submenu {
  /*Used for bootstrap dropdowns on report page*/
  display: block;
}

input[type=radio],
input[type=checkbox] {
  pointer-events: auto;
}

input[type=text],
input[type=password],
select {
  /*this is for text fields*/
  width: 200px;
}

.a-nav,
.a-nav:hover {
  color: #000000;
  text-decoration: none;
}

.sort-th {
  /*styles table headers, makes them clickable. Used for sorting*/
  cursor: pointer;
  pointer-events: auto;
  /* color: #002b54; */
  font-weight: bold;
}

.admin-arrow {
  /*Icon used for sorting, used in table headers that can be sorted*/
  /* border-color: #002b54; */
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  float: right;
}

.AccountDropdowns {
  width: 30rem;
}

.ascending {
  border-bottom: 5px solid;
}

.descending {
  border-top: 5px solid;
}

li.notSelected {
  /*Used on order page*/
  color: #007bff;
  pointer-events: all;
  cursor: pointer;
}

a.reverse,
li.reverse {
  /*Used on proof page in sidebar list*/
  color: white;
  pointer-events: all;
  cursor: pointer;
}

a.selected,
li.selected,
a.active {
  /* This is for links that are selected, like on orders page */
  font-weight: bold;
  color: #eb6600 !important;
  pointer-events: all;
  cursor: pointer;
}

#redirect {
  color: #007bff;
  text-decoration: none;
  cursor: pointer;
  border: none;
  background: none;
}

#redirect:hover {
  text-decoration: underline;
}

.action:hover {
  text-decoration: underline;
}

.action {
  color: #007bff !important;
  text-decoration: none;
  cursor: pointer;
  border: none;
  background: none;
}

#router {
  justify-content: flex-start;
}

#router>a {
  font-size: 20px;
  color: grey;
  text-decoration: none;
  padding-left: 10px;
  padding-right: 10px;
}

#footer {
  background-color: #002b54;
  color: white;
  font-size: 1rem;
  position: absolute;
  bottom: 0;
  width: 100%;
}

#title1 {
  background-color: #002b54;
  color: white;
  text-align: center;
  width: 50%;
  margin: auto;
  margin-bottom:10px;
  margin-top: 10px;
  padding: 20px 20px 7px 25px;
}

#sidetitle {
  background-color: #56a0d3;
  color: white;
  text-align: center;
  padding: 2%;
}

.background {
  background: linear-gradient(145deg, rgba(255, 255, 255, 1) 10%, rgba(86, 160, 211, .6) 100%);
  background-attachment: fixed;
  transition: background 0.9s ease, padding 0.8s linear;
  /* background: rgb(255, 255, 255); */
  /* background-color: #56a0d3; */
  /* background-repeat:no-repeat; */
}

.background.uhm {
  background: #DCDCDC;
  background-attachment: fixed;
}

.background.atproperties {
  background: #DCDCDC;
  background-attachment: fixed;
}

.background.twomen {
  /* background: #DCDCDC; */
  background: linear-gradient(145deg, rgba(255, 255, 255, 1) 10%, #D2D2D2 100%);
  background-attachment: fixed;
}

.loginInput {
  background-color: white;
  width: 300px !important;
  border: 1px solid black;
  border-radius: 4px;
}

.loginInput:focus {
  background-color: white;
  border: 1px solid #eb6600;
  border-radius: 4px;
  outline: none;
}

.card.border-secondary {
  border-radius: 12px;
  box-shadow: 0 2px 4px 0 black;
}

.tighttables {
  margin: 0px;
}

.tighttables>thead>tr>th,
.tighttables>tbody>tr>td {
  padding-top: 4px;
  padding-bottom: 4px;
  padding-left: 5px;
  padding-right: 2px;
}

.tightcards>div {
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 8px;
  padding-right: 8px;
}